import { Injectable } from '@angular/core';
import { ColorationContext } from '@core/models/coloration-context.model';
import { Config } from '@core/models/config.model';
import { PistonTrack } from '@core/models/piston-track.model';
import { PistonType } from '@core/models/piston-type.model';
import { Piston } from '@core/models/piston.model';
import { Referential } from '@core/models/referential.model';
import { SimulatedProperties } from '@core/models/simulated-properties.model';
import { SimulationMetadata } from '@core/models/simulation-metadata.model';
import { Thresholds } from '@core/models/threshold.model';
import { AuthService } from '@core/services/auth.service';
import { ColorationContextService } from '@core/services/coloration-context.service';
import { ConfigService } from '@core/services/config.service';
import { PistonTrackService } from '@core/services/piston-track.service';
import { PistonTypeService } from '@core/services/piston-type.service';
import { PistonService } from '@core/services/piston.service';
import { SimulationService } from '@core/services/simulation.service';
import { ThresholdService } from '@core/services/threshold.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Equipment } from '@shared/gui/models/equipment.model';
import { forkJoin } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

import { EquipmentService } from '../services/equipment.service';
import { ReferentialService } from '../services/referential.service';
import { SignalRService } from '../services/signalr.service';
import { CoreActions } from '../store';

import { State } from './core.reducer';
import { GasBlockBound } from '@core/models/gas-block-bound.model';
import { GasBlockBoundService } from '@core/services/gas-block-bound.service';
import { DashboardService } from '@core/services/dashboard.service';
import { Dashboard } from '@app/home/features/dashboard/models/dashboard.model';
import { FavoritePathService } from '@core/services/favorite-path.service';
import { FavoritePath } from '@app/home/features/gas-block/models/favorite-path.model';
import { IndametaService } from '@app/home/features/topology/services/indameta.service';
import { ErrorService } from '@core/services/error.service';
import { ErrorTemplate } from '@app/home/features/error/models/error.model';

@Injectable()
export class CoreEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private authService: AuthService,
    private configService: ConfigService,
    private signalRService: SignalRService,
    private equipmentService: EquipmentService,
    private referentialService: ReferentialService,
    private pistonTypeService: PistonTypeService,
    private pistonTrackService: PistonTrackService,
    private pistonService: PistonService,
    private colorationContextService: ColorationContextService,
    private simulationService: SimulationService,
    private thresholdsService: ThresholdService,
    private gasBlockBoundService: GasBlockBoundService,
    private favoritePathService: FavoritePathService,
    private dashboardService: DashboardService,
    private errorService: ErrorService,
    private indametaV2Service: IndametaService
  ) {}

  initData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.initData),
      switchMap(() => {
        this.store.dispatch(CoreActions.showSpinner());
        this.indametaV2Service.getDataStructureIdAndTypesId().subscribe();
        return forkJoin([
          this.configService.getConfig().pipe(take(1)),
          this.equipmentService.getEquipments().pipe(take(1)),
          this.referentialService.getReferentials().pipe(take(1)),
          this.pistonTypeService.getPistonTypes().pipe(take(1)),
          this.pistonTrackService.getPistonTracks().pipe(take(1)),
          this.pistonService.getPistons().pipe(take(1)),
          this.colorationContextService.getColorationContext().pipe(take(1)),
          this.simulationService.getSimulatedProperties().pipe(take(1)),
          this.simulationService.getSimulationMetadata().pipe(take(1)),
          this.thresholdsService.getThresholds().pipe(take(1)),
          this.gasBlockBoundService.getGasBlockBounds().pipe(take(1)),
          this.favoritePathService.getFavoritePaths().pipe(take(1)),
          this.dashboardService.getPublics().pipe(take(1)),
          this.errorService.getErrorTemplates().pipe(take(1))
        ]).pipe(
          switchMap(
            ([
              config,
              equipments,
              referentials,
              pistonTypes,
              pistonTracks,
              pistons,
              colorationContexts,
              simulatedProperties,
              simulationMetadatas,
              thresholds,
              gasBlockBounds,
              favoritePaths,
              dashboards,
              errorTemplates
            ]: [
              Config,
              Array<Equipment>,
              Array<Referential>,
              Array<PistonType>,
              Array<PistonTrack>,
              Array<Piston>,
              Array<ColorationContext>,
              SimulatedProperties,
              Array<SimulationMetadata>,
              Thresholds,
              Array<GasBlockBound>,
              Array<FavoritePath>,
              Array<Dashboard>,
              Array<ErrorTemplate>
            ]) => [
              CoreActions.initDataSuccess({
                config,
                equipments,
                referentials,
                pistonTypes,
                pistonTracks,
                pistons,
                colorationContexts,
                simulatedProperties,
                simulationMetadatas,
                thresholds,
                gasBlockBounds,
                favoritePaths,
                errorTemplates
              }),
              CoreActions.updateDashboardsReferential({ dashboards }),
              // ErrorActions.saveError({ error: { errorType: ErrorType.PRSIM_CRASH } as Error }),
              CoreActions.hideSpinner()
            ]
          )
        );
      })
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.logout),
        tap(() => this.authService.logout())
      ),
    { dispatch: false }
  );

  // SignalR
  connect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.connect),
        tap(() => this.signalRService.connect())
      ),
    { dispatch: false }
  );

  disconnect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CoreActions.disconnect),
        tap(() => this.signalRService.disconnect())
      ),
    { dispatch: false }
  );

  reconnect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CoreActions.reconnect),
      switchMap(() => {
        return this.simulationService
          .getSimulationMetadata()
          .pipe(
            switchMap(simulationMetadatas =>
              simulationMetadatas.map(simulation =>
                CoreActions.updateLatestSimulationResult({ simulationResult: simulation.simulationResult })
              )
            )
          );
      })
    )
  );
}
